<template>
  <section class="news-info">
    <!-- <ImageItem /> -->
    <el-image fit="cover" v-if="newsInfo.topImageUrl" :src="newsInfo.topImageUrl"></el-image>
    <div class="title">
      {{ newsInfo.title }}
    </div>
    <div class="content">
      <div class="text">
        {{ newsInfo.context }}
      </div>
      <el-image fit="cover" v-if="newsInfo.showImg" :src="newsInfo.showImg"></el-image>
      <!-- <el-image
        fit="cover"
        src="https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg"
      ></el-image> -->
    </div>
  </section>
</template>

<script>
// import ImageItem from "./image-item.vue";
import ApiService from '@/api/apiService';
import API from '@/api';
import { LanguageEnum, Language } from '@/utils/constants';

export default {
  name: 'news-info',
  data() {
    return {
      newsInfo: {}
    };
  },
  components: {
    // ImageItem,
  },
  watch: {
    '$store.state.isChinese'() {
      console.log('hahah');
      this.getNewsDetail(this.$route.params.id);
    }
  },
  mounted() {
    console.log('123456');
    this.getNewsDetail(this.$route.params.id);
  },
  methods: {
    async getNewsDetail(id) {
      const result = await ApiService.get(API.newsDetail + id, {
        language: LanguageEnum[this.$store.state.isChinese ? Language.CN : Language.EN]
      });
      this.newsInfo = result.data;
      // this.bannerList = result?.data?.desc || [];
    }
  }
};
</script>

<style scoped lang="less">
.news-info {
  .title {
    margin: 2.8vw 0 1.4vw;
    font-size: 1.67vw;
    color: #1a2043;
  }
  .content {
    .text {
      text-align: left;
    }
  }
}
</style>
